import React from "react"

const EditorHr: React.FC = () => {
  return (
    <div style={{ width: "100%" }}>
      <hr />
    </div>
  )
}
export default EditorHr
